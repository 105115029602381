<template>
  <section class="section">
    <furniture-sales-search-form></furniture-sales-search-form>

    <h4 class="title has-text-centered is-4">Furniture Sales</h4>
    <b-table
      :data="furniture_sales.data"
      :striped="true"
      :hoverable="true"
      :loading="loadingFurnitureSales"
      class="mt-4 clickable-table user-table"
    >
      <b-table-column field="id" label="ID" v-slot="props">
        <p>
          {{ props.row.id }}
        </p>
      </b-table-column>

      <b-table-column field="created_at" label="Date" v-slot="props">
        <p>{{ props.row.created_at | dateStandard }}</p>
      </b-table-column>

      <b-table-column field="buyer_id" label="User" v-slot="props">
        <p>
          <strong><router-link :to="`users/${props.row.buyer.id}`">{{ props.row.buyer.name }}</router-link></strong>
          <br />
          {{ props.row.buyer.email }}
        </p>
      </b-table-column>

      <b-table-column field="furniture_id" label="Furniture" v-slot="props">
        <p>
          <strong><router-link :to="`furniture/${props.row.furniture.id}`">{{ props.row.furniture.name }}</router-link></strong>
        </p>
      </b-table-column>

      <b-table-column field="currants_price" label="Price" v-slot="props">
        <p v-if="props.row.currants_price">{{ props.row.currants_price }} c</p>
        <p v-else><strong>{{ props.row.monetti_price }} M</strong></p>
      </b-table-column>
    </b-table>

    <b-pagination
      class="mt-4"
      v-model="furniture_sales.current_page"
      :total="furniture_sales.total"
      :per-page="furniture_sales.per_page"
      range-before="3"
      range-after="3"
      order="is-centered"
      icon-pack="fas"
      @change="handlePageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">
    </b-pagination>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import FurnitureSalesSearchForm from '@/components/Furniture/FurnitureSalesSearchForm.vue';

export default {
  name: 'Transactions',
  components: { FurnitureSalesSearchForm },
  computed: {
    ...mapState('furniture', ['loadingFurnitureSales', 'furniture_sales']),
  },
  mounted() {
    this.loadFurnitureSales();
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.query': function () {
      this.loadFurnitureSales();
    },
  },
  methods: {
    loadFurnitureSales() {
      this.$store.dispatch('furniture/loadFurnitureSales', this.$route.query);
    },
    handlePageChange(pageNum) {
      const params = {
        ...this.$route.query,
        page: pageNum,
      };
      const queryString = Object.keys(params).map((key) => `${key}=${params[key] || ''}`).join('&');

      this.$router.push({ path: `/furniture-sales?${queryString}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
